import * as api from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  createBatchLiquidOxygenLifecycles(context, params) {
    return new Promise((resolve, reject) => {
      api
        .createBatchLiquidOxygenLifecycles(params)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
