module.exports = {
  addProductByScan: "addProductByScan",
  addProductManually: "addProductManually",
  authBan: "authBan",
  baseDocumentaire: "baseDocumentaire",
  communityEntityInterventions: "communityEntityInterventions",
  communityTour: "communityTour",
  customProtocol: "customProtocol",
  deliveryNote: "deliveryNote",
  fastpay: "fastpay",
  faxes: "faxes",
  interventionReport: "interventionReport",
  interventions: "interventions",
  liquidOxygenLifecycle: "liquidOxygenLifecycle",
  mailToPatientForNewAlert: "mailToPatientForNewAlert",
  maintenance: "maintenance",
  messaging: "messaging",
  offlinePdf: "offlinePdf",
  optitime: "optitime",
  ordoclic: "ordoclic",
  patientSignature: "patientSignature",
  patientsOrientedMessages: "patientsOrientedMessages",
  patientTelesuiviSynchroButton: "patientTelesuiviSynchroButton",
  planificationActionButtons: "planification.actionButtons",
  planificationGlobal: "planification.global",
  planificationOnContractCreation: "planification.onContractCreation",
  planificationOnInterventionAchieved: "planification.onInterventionAchieved",
  prescriberAccount: "prescriberAccount",
  productsReminder: "productsReminder",
  questionnaires: "questionnaires",
  reminders: "reminders",
  riskAnalysis: "riskAnalysis",
  selectMessageTypeOnExtranet: "selectMessageTypeOnExtranet",
  sleepStudies: "sleepStudies",
  sms: "sms",
  stockHandler: "stockHandler",
  support: "support",
  synchroPatient: "synchroPatient",
  synchroStocks: "synchroStocks",
  telesuiviAlerts: "telesuiviAlerts",
  woocommerce: "woocommerce",
  extranet: "extranet"
};
