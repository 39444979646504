import getDefaultDivision from "@ui/helpers/_functions/getDefaultDivision";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.admin.products";

// View
const ProductViewSheet = () => import(/* webpackChunkName: "product" */ "@ui/pages/products/view/_components/Sheet");
const ProductViewTechnicalNews = () => import(/* webpackChunkName: "product" */ "@ui/pages/products/view/_components/TechnicalNews");

// Edit
const ProductEditSheet = () => import(/* webpackChunkName: "product" */ "@/modules/products/edit/_components/ProductEditSheet");
const ProductEditTechnicalNews = () => import(/* webpackChunkName: "product" */ "@/modules/products/edit/_components/ProductEditTechnicalNews");

const Products = () => import(/* webpackChunkName: "product" */ "@/modules/products/list");
const ProductView = () => import(/* webpackChunkName: "product" */ "@/modules/products/view");

const StockCamion = () => import(/* webpackChunkName: "product" */ "@/modules/products/stock/_components/StockCamion");
const StockPole = () => import(/* webpackChunkName: "product" */ "@/modules/products/stock/_components/StockPole");
const StockTransfert = () => import(/* webpackChunkName: "product" */ "@/modules/products/stock/_components/StockTransfert");

// Bouteilles O2 gazeux
const GasOxygenMonitoring = () => import(/* webpackChunkName: "product" */ "@/modules/products/gas-oxygen-monitoring");
const GasOxygenHistory = () => import(/* webpackChunkName: "product" */ "@/modules/products/gas-oxygen-history");

// Liquid o2 tank
const LiquidOxygenMonitoring = () => import(/* webpackChunkName: "product" */ "@/modules/products/liquid-oxygen-monitoring");
const IndexOxygenLiquideFraction = () =>
  import(/* webpackChunkName: "product" */ "@/modules/products/liquid-oxygen-fraction/IndexOxygenLiquideFraction");

const {
  menuRights: { VIEW_MENU_STOCK_POLE },
  productsRights: { ACCESS_PRODUCT, EDIT_PRODUCT, ACCESS_GAS_OXYGEN_MONITORING, LIQUID_OXYGEN_ACCESS_MONITORING, LIQUID_OXYGEN_ACCESS_FRACTION }
} = require("@common/services/acm/rights");

export default [
  {
    path: "products",
    alias: "produits",
    name: "products",
    redirect: () => `products/${getDefaultDivision()}`
  },
  {
    path: "stock-tech",
    alias: "stock-camion",
    name: "productsStock",
    component: StockCamion,
    meta: {
      title: localize(localPath, "title--productsStock"),
      rights: [ACCESS_PRODUCT]
    }
  },
  {
    path: "stock-pole",
    name: "productsStockPole",
    component: StockPole,
    meta: {
      title: localize(localPath, "title--productsStockPole"),
      rights: [VIEW_MENU_STOCK_POLE]
    }
  },
  {
    path: "stock-transfert",
    name: "productsStockTransfert",
    component: StockTransfert,
    meta: {
      title: localize(localPath, "title--productsStockTransfert"),
      rights: [VIEW_MENU_STOCK_POLE]
    }
  },
  {
    path: "products/:division",
    alias: "produits/:division",
    name: "productsList",
    component: Products,
    meta: {
      title: localize(localPath, "title--productsList"),
      rights: [ACCESS_PRODUCT]
    }
  },
  {
    path: "products/oxygen-gas/monitoring",
    alias: "produits/oxygene-gazeux/suivi",
    name: "productAgaMonitoring",
    component: GasOxygenMonitoring,
    meta: {
      title: localize(localPath, "title--productAgaMonitoring"),
      rights: [ACCESS_GAS_OXYGEN_MONITORING]
    }
  },
  {
    path: "products/oxygen-gas/history",
    alias: "produits/oxygene-gazeux/historique",
    name: "productAgaHistory",
    component: GasOxygenHistory,
    meta: {
      title: localize(localPath, "title--productAgaHistory"),
      rights: [ACCESS_GAS_OXYGEN_MONITORING]
    }
  },
  {
    path: "/produits/oxygene-liquid/suivi",
    name: "productTankMonitoring",
    component: LiquidOxygenMonitoring,
    meta: {
      title: "Oxygène liquide",
      rights: [LIQUID_OXYGEN_ACCESS_MONITORING]
    }
  },
  {
    path: "/produits/oxygene-liquid/fraction",
    name: "productO2fraction",
    component: IndexOxygenLiquideFraction,
    meta: {
      title: "Oxygène liquide",
      rights: [LIQUID_OXYGEN_ACCESS_FRACTION]
    }
  },
  {
    path: "product/:id",
    alias: "produit/:id",
    component: ProductView,
    children: [
      {
        path: "",
        name: "productView",
        meta: {
          title: localize(localPath, "title--productView"),
          rights: [ACCESS_PRODUCT]
        },
        component: ProductViewSheet
      },
      {
        path: "technical-news",
        alias: "actualite-technique",
        name: "productViewTechnicalNews",
        meta: {
          title: localize(localPath, "title--productView"),
          rights: [ACCESS_PRODUCT]
        },
        component: ProductViewTechnicalNews
      },
      {
        path: "card/edit",
        alias: "fiche/edition",
        name: "productEditSheet",
        meta: {
          title: localize(localPath, "title--productEdit"),
          rights: [EDIT_PRODUCT]
        },
        component: ProductEditSheet
      },
      {
        path: "technical-news/edit",
        alias: "actualite-technique/edition",
        name: "productEditTechnicalNews",
        meta: {
          title: localize(localPath, "title--productEdit"),
          rights: [EDIT_PRODUCT]
        },
        component: ProductEditTechnicalNews
      }
    ]
  }
];
