import http from "@/services/http";

export const fetchLiquidOxygenLifecycles = params => {
  return http.get("/liquid-oxygen-lifecycles", {
    params
  });
};

export const exportLiquidOxygenLifecycles = params => {
  return http.get("/liquid-oxygen-lifecycles/export", {
    params
  });
};

export const searchLiquidOxygenLifecyclesBatchNumbers = params => {
  return http.get("/liquid-oxygen-lifecycles/search/batch-number", {
    params
  });
};

export const searchLiquidOxygenLifecyclesSerialNumbers = params => {
  return http.get("/liquid-oxygen-lifecycles/search/serial-number", {
    params
  });
};
