import {
  fetchLiquidOxygenLifecycles,
  exportLiquidOxygenLifecycles,
  searchLiquidOxygenLifecyclesBatchNumbers,
  searchLiquidOxygenLifecyclesSerialNumbers
} from "../_api";

const state = {
  items: [],
  count: 0,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  items: state => state.items,
  count: state => state.count,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = items;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  formatQuery(context, query) {
    const { serialNumber, batchNumbers, movementTypes, fractionDate, stockMovementDate, ...formattedQuery } = query;
    if (serialNumber && serialNumber.length) {
      formattedQuery.serialNumbers = serialNumber.map(item => item.serialNumber);
    }
    if (batchNumbers && batchNumbers.length) {
      formattedQuery.batchNumbers = batchNumbers.map(item => item.batchNumber);
    }
    if (movementTypes && movementTypes.length) {
      formattedQuery.movementTypes = movementTypes;
    }
    if (stockMovementDate) {
      formattedQuery.stockMovementDateStart = stockMovementDate.start || undefined;
      formattedQuery.stockMovementDateEnd = stockMovementDate.end || undefined;
    }
    if (fractionDate) {
      formattedQuery.fractionDateStart = fractionDate.start || undefined;
      formattedQuery.fractionDateEnd = fractionDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  exportLiquidOxygenLifecycles(context, params) {
    const { formattedQuery } = context.state;
    return exportLiquidOxygenLifecycles({ ...params, query: formattedQuery });
  },
  fetchLiquidOxygenLifecycles(context, { requestId, query, ...params }) {
    return new Promise((resolve, reject) => {
      context.dispatch("formatQuery", query);
      context.commit("SET_LAST_REQUEST_ID", requestId);
      const { formattedQuery, lastRequestId } = context.state;

      fetchLiquidOxygenLifecycles({ ...params, query: formattedQuery })
        .then(res => {
          if (!res?.data?.body || lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;

          context.commit("SET_ITEMS", results);
          context.commit("SET_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchLiquidOxygenLifecyclesBatchNumbers(context, batchNumberInput) {
    return searchLiquidOxygenLifecyclesBatchNumbers({ query: { batchNumberInput } });
  },

  searchLiquidOxygenLifecyclesSerialNumbers(context, serialNumberInput) {
    return searchLiquidOxygenLifecyclesSerialNumbers({ query: { serialNumberInput } });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
